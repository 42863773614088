import React from 'react';
import './Signupcomp.css';
import { Link } from 'react-router-dom';

function Signupcomp() {

  const laslocFunc = () => {
      const path = window.location.pathname;
      localStorage.setItem('lasloc', path);
    //   console.log(path);
  }

  return (
    <div className='main-signupcomp w-100 mt-5 pt-5'>
        <div className="signup-comp-wrap rounded-brand mx-auto p-5">
            <h2 className="text-center fs-2 mb-1">Get <span className="body-font-bold text-brand-pink">Started</span></h2>
            <p className="mb-5 text-muted body-font-medium text-center">Please Signup/Login to continue forward</p>
            <Link to='/signup' className="nav-link mb-3 border body-font-bold fs-4 text-center border-brand-pink rounded-brand-sm p-3 bg-brand-light-pink" onClick={laslocFunc}>
                Sign Up
            </Link>
            <Link to='/login' className="nav-link mb-0 border body-font-bold fs-4 text-center border-dark rounded-brand-sm p-3" onClick={laslocFunc}>
                Login
            </Link>
            <p className="mb-0 mt-4 text-muted fs-sm body-font-medium text-center">By Signing/Loggin in, you are accepting <span className="text-brand-pink body-font-bold">Shiawedding's</span> <Link to="/terms-and-conditions">Terms & Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link></p>
        </div>
    </div>
  )
}

export default Signupcomp;