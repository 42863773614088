import React, { useCallback, useEffect, useState } from 'react';
import './Dashboard.css';
import Dashmenu from '../../Components/Dashmenu/Dashmenu';
import { Outlet } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from '../../Context/Authcontext';

function Dashboard() {

    const { setFildate } = useAuth();
    const [isroot, setIsroot] = useState(true);
    const [datefilter, setDatefilter] = useState('all time');
    const [datedrop, setDatedrop] = useState(false);
    const [startdate, setStartdate] = useState(new Date());
    const [enddate, setEnddate] = useState(new Date());
    const [date, setDate] = useState(new Date().toLocaleDateString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'}));
    
    const setFildateFunc = useCallback(d => {
        setFildate(d);
    }, [setFildate]);

    useEffect(()=> {
        setFildateFunc(date);
    }, [date, setFildateFunc]);

    setInterval(() => {
        if(window.location.pathname === '/admin' || window.location.pathname === '/admin/'){
            setIsroot(true)
        }else{
            setIsroot(false)
        }
    }, 500);

    useEffect(()=>{
        if(window.location.pathname === '/admin/' || window.location.pathname === '/admin'){
            setIsroot(true)
            // console.log('hello')
        }else{
            setIsroot(false)
            // console.log('hello')
        }
    }, []);

    // useEffect(()=> console.log(startdate, 'some', enddate), [startdate, enddate]);

    useEffect(()=> {
        const dateoptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'Asia/Kolkata'
        };

        const tempdate = new Date();
        if(datefilter.toLowerCase() === 'today'){
            // console.log(tempdate.toLocaleDateString('en-US', dateoptions));
            setDate(tempdate.toLocaleDateString('en-US', dateoptions));
        }else if(datefilter.toLowerCase() === 'this week'){
            const tempday = tempdate.getDay();
            const startweek = new Date(tempdate);
            startweek.setDate(tempdate.getDate() - tempday);
            startweek.setHours(0,0,0,0);

            const endweek = new Date(tempdate);
            endweek.setDate(tempdate.getDate() + (6 - tempday));
            endweek.setHours(23, 59, 59, 999);

            // console.log(startweek.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}), endweek.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}));
            setDate(startweek.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}) +'-'+ endweek.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}));
        }else if(datefilter.toLowerCase() === 'this month'){
            const startmonth = new Date(tempdate.getFullYear(), tempdate.getMonth(), 1);
            startmonth.setHours(0, 0, 0, 0);

            const endmonth = new Date(tempdate.getFullYear(), tempdate.getMonth() + 1, 0);
            endmonth.setHours(23, 59, 59, 999);
            // console.log(startmonth.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}), endmonth.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}))
            setDate(startmonth.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}) + '-' + endmonth.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}))
        }else if(datefilter.toLowerCase() === 'this year'){
            const startyear = new Date(tempdate.getFullYear(), 0, 1);
            startyear.setHours(0, 0, 0, 0);

            const endyear = new Date(tempdate.getFullYear(), 11, 31);
            endyear.setHours(23, 59, 59, 999);
            // console.log(startyear.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}), endyear.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}))
            setDate(startyear.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}) +'-'+ endyear.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year:'numeric'}))
        }else if(datefilter.toLowerCase() === 'all time'){
            setDate('all time');
        }else if(startdate !== '' && enddate !== ''){
            setDate(`${startdate}-${enddate}`);
        }
    }, [datefilter, startdate, enddate])

    // useEffect(()=> console.log(date), [date]);

  return (
    <div className='main-dashboard p-0'>
            {
                isroot? (
                    <div>
                        <Outlet/>
                    </div>
                ):(
                    <div className="container-fluid">
                        <div className="row py-3">
                            <div className="col-md-2">
                                <Dashmenu/>
                            </div>
                            <div className="col-md-10 pages-dash">
                                <div className="datefilter-wraper mb-4">
                                    <div className="date-filter-wrap d-flex">
                                        <input type="text" name="datefilter" id="datefilter" className='datefilter-inpu form-control border-0 shadow-none bg-brand-pink text-white rounded-pill p-2 px-3 text-center outline-none text-capitalize body-font-bold' value={datefilter} onChange={(e) => setDatefilter(e.target.value)} disabled placeholder='filter by date'/>
                                        <p className="mb-0 rounded-brand bg-brand-light-pink px-3 d-flex justify-content-center align-items-center ms-1 cursor-pointer" onClick={()=> setDatedrop(datedrop? false: true)}><i className="fas fa-chevron-down text-brand-pink fs-5"></i></p>
                                    </div>
                                    <div className={`datefilter-options bg-white p-3 rounded-brand shadow mt-2 flex-wrap ${!datedrop? 'd-none': 'd-flex'}`}>
                                        <div className="datefilter-option-wrap">
                                            <p className="mb-0 datefilter border border-brand-pink rounded-pill bg-brand-light-pink text-brand-pink text-center mt-1 p-1 px-3 body-font-bold text-nowrap text-capitalize cursor-pointer" onClick={(e)=> {setDatefilter(e.target.innerText); setDatedrop(false)}}>today</p>
                                            <p className="mb-0 datefilter border border-brand-pink rounded-pill bg-brand-light-pink text-brand-pink text-center mt-1 p-1 px-3 body-font-bold text-nowrap text-capitalize cursor-pointer" onClick={(e)=> {setDatefilter(e.target.innerText); setDatedrop(false)}}>this week</p>
                                            <p className="mb-0 datefilter border border-brand-pink rounded-pill bg-brand-light-pink text-brand-pink text-center mt-1 p-1 px-3 body-font-bold text-nowrap text-capitalize cursor-pointer" onClick={(e)=> {setDatefilter(e.target.innerText); setDatedrop(false)}}>this month</p>
                                            <p className="mb-0 datefilter border border-brand-pink rounded-pill bg-brand-light-pink text-brand-pink text-center mt-1 p-1 px-3 body-font-bold text-nowrap text-capitalize cursor-pointer" onClick={(e)=> {setDatefilter(e.target.innerText); setDatedrop(false)}}>this Year</p>
                                            <p className="mb-0 datefilter border border-brand-pink rounded-pill bg-brand-light-pink text-brand-pink text-center mt-1 p-1 px-3 body-font-bold text-nowrap text-capitalize cursor-pointer" onClick={(e)=> {setDatefilter(e.target.innerText); setDatedrop(false)}}>all time</p>
                                        </div>
                                        <div className="dadepicker-wrap d-flex">
                                            <div className="start-date datepicker mt-2 ms-2">
                                                <p className="mb-0 text-brand-pink ms-2 body-font-semibold text-capitalize">from </p>
                                                <DatePicker maxDate={new Date()} inline showYearDropdown yearDropdownItemNumber={30} scrollableYearDropdown selected={startdate} onChange={(e) => {
                                                    setStartdate(e.toLocaleDateString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'})); 
                                                    setDatefilter(`${e.toLocaleDateString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'})} - ${typeof(enddate) === 'string'? enddate: enddate?.toLocaleDateString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'})}`)
                                                }}/>
                                            </div>
                                            <div className="end-date datepicker mt-2 ms-2">
                                                <p className="mb-0 text-brand-pink ms-2 body-font-semibold text-capitalize">till </p>
                                                <DatePicker maxDate={new Date()} inline showYearDropdown yearDropdownItemNumber={30} scrollableYearDropdown selected={enddate} onChange={(e) => {
                                                    setEnddate(e.toLocaleDateString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'}));
                                                    setDatefilter(`${startdate} - ${e.toLocaleDateString('en-US', {day:'2-digit',month:'2-digit',year:'numeric'})}`); 
                                                    setDatedrop(false)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                )
            }
    </div>
  )
}

export default Dashboard