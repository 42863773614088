import React, { useEffect, useState } from 'react';
import './Profile.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';
import Signupcomp from '../../Components/Signupcomp/Signupcomp';

function Profile() {

    const { ranid } = useAuth();
    const { profiledata } = useAuth();
    const { logout } = useAuth();
    const [confirmpop, setConfirmpop] = useState(false);
    const [confirmdel, setConfirmdel] = useState(false);
    const [notidis, setNotidis] = useState('');

    useEffect(()=> window.scrollTo(0,0), []);

    const logoutFunc = () => {
        localStorage.clear();
        logout();
    }

    const sendVerificaitonRequest = () => {
        fetch(`https://shiawedding.onrender.com/verificationreq/${profiledata._id}`, {method: 'POST'}).then(res => res.json()).then(data => {
            // console.log(data);
            if(data.success){
                setNotidis('Requset for profile verification sent successfully');
                setConfirmpop(false);
                setTimeout(() => {
                    setNotidis('');
                }, 3000);
            }else{
                setNotidis(data.msg);
                setConfirmpop(false);
                setTimeout(() => {
                    setNotidis('');
                }, 3000);
            }
        }).catch(err => console.log('error occured while sending the request for profile verification ', err));
    };

    const sendDeleteReq = () => {
        if(profiledata){
            fetch(`https://shiawedding.onrender.com/delreq/${profiledata._id}`, {method: "POST"}).then(res => res.json()).then(data => {
                console.log(data);
                if(data.success){
                    setNotidis('Requset for profile deletion has been sent successfully');
                    setConfirmdel(false);
                    setTimeout(() => {
                        setNotidis('');
                    }, 3000);
                }else{
                    setNotidis(data.msg);
                    setConfirmdel(false);
                    setTimeout(() => {
                        setNotidis('');
                    }, 3000);
                }
            })
        }
    }

  return (
    <div className='main-profile'>
        
            {
                ranid !== ''? (
                    <div className="profile-container shadow rounded-brand p-3">
                        <div className="p-0 mb-0">
                            <div className="profile-details d-flex align-items-center">
                                <div className="profile-image overflow-hidden rounded-circle me-3">
                                    <img src={`https://shiawedding.onrender.com/images/${profiledata?.profileimage?.split('/')[1]}`} alt="" className="img-fluid" />
                                </div>
                                <div className="profile-data-details">
                                    <p className="mb-0 fs-5 body-font-extrabold text-capitalize">{profiledata.firstname} {profiledata.lastname}</p>
                                    <p className="mb-0 body-font-extrabold text-muted">{profiledata.email}</p>
                                    <p className="mb-0 nav-link body-font-extrabold text-muted">{profiledata.randomid}</p>
                                </div>
                            </div>
                            <div className="profile-links-wrap mt-5">
                                <Link to={`/user/${ranid}`} className="nav-link border-bottom profile-link p-2 body-font-semibold">My Profile</Link>
                                <Link to={`/editprofile`} className="nav-link border-bottom profile-link p-2 body-font-semibold">Edit Profile</Link>
                                <p onClick={()=> setConfirmpop(true)} className="mb-0 border-bottom profile-link p-2 body-font-semibold cursor-pointer">Send Verification Request</p>
                                <Link to="/saved" className="nav-link border-bottom profile-link p-2 body-font-semibold">Saved</Link>
                                <Link to="/terms-and-conditions" className="nav-link border-bottom profile-link p-2 body-font-semibold">Terms & conditions</Link>
                                <Link to="/privacy-policy" className="nav-link border-bottom profile-link p-2 body-font-semibold">Privacy Policy</Link>
                                <Link to="/disclaimer" className="nav-link border-bottom profile-link p-2 body-font-semibold">Disclaimer</Link>
                                <Link to="/customer-support" className="nav-link border-bottom profile-link p-2 body-font-semibold">Customer Support</Link>
                                <Link to="/about-us" className="nav-link border-bottom profile-link p-2 body-font-semibold">About Us</Link>
                                <p className="mb-0 border-bottom profile-link p-2 body-font-semibold cursor-pointer" onClick={logoutFunc}>Log Out</p>
                                <p className="border-bottom profile-link p-2 body-font-semibold text-danger cursor-pointer" onClick={()=> setConfirmdel(true)}>Request To Delet Profile</p>
                            </div>
                        </div>
                        <div className={`confirm-popup-wrap ${confirmpop ? 'd-flex' : 'd-none'}`}>
                            <div className="confirm-popup bg-white rounded-brand d-flex flex-column p-3 px-4">
                                <p className="mb-0 body-font-bold text-muted">Send profile for verification?</p>
                                <div className="row p-0 mt-4">
                                    <div className="col-6">
                                        <button className="btn btn-outline-brand-pink body-font-bold w-100" onClick={() => setConfirmpop(false)}><i className="fas fa-times me-2"></i>No</button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-brand-pink body-font-bold w-100" onClick={sendVerificaitonRequest}><i className="fas fa-check me-2"></i>Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`confirm-popup-wrap ${confirmdel ? 'd-flex' : 'd-none'}`}>
                            <div className="confirm-popup bg-white rounded-brand d-flex flex-column p-3 px-4">
                                <p className="mb-0 body-font-bold text-muted">Send profile for <span className="text-danger body-font-semibold">Deletion</span>?</p>
                                <div className="row p-0 mt-4">
                                    <div className="col-6">
                                        <button className="btn btn-outline-brand-pink body-font-bold w-100" onClick={() => setConfirmdel(false)}><i className="fas fa-times me-2"></i>No</button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-brand-pink body-font-bold w-100" onClick={sendDeleteReq}><i className="fas fa-check me-2"></i>Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="noti-wrap py-3 px-2" style={{display: notidis !== ''? 'block': 'none'}}>
                            <p className="mb-0 body-font-bold text-success border border-2 rounded-brand-sm p-3 py-2 border-success bg-success-subtle text-capitalize">{notidis}</p>
                        </div>
                    </div>
                ): (
                    <div className="w-100">
                        <Signupcomp/>
                    </div>
                )
            }
    </div>
  )
}

export default Profile