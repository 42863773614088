import React, { useEffect } from 'react';
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';

function Home() {

    const navigate = useNavigate();

    useEffect(() => window.scrollTo(0, 0), []);

    const localSearchFunc = () => {
        localStorage.setItem('localsearch', 'home');
        navigate('/explore')
    }

  return (
    <div className='main-home container-fluid p-0'>
        <div className="hero-secton bg-brand-light-pink pt-5 mb-5">
            <div className="container">
                <div className="hero-text-wrap">
                    <h1 className="hero-text body-font-heading text-brand-purple">
                        "And we created you in pairs"
                    </h1>
                    <p className="mb-0 fs-5">
                    [ Al Quran, Surah An-Naba (78), Aayah 8 ]
                    </p>
                </div>
                <div className="search-box-wrap nav-link mt-5">
                    <div className="search-box row d-flex bg-white p-4 rounded-brand-sm w-50 justify-content-between px-0" onClick={localSearchFunc}>
                       <div className="col-3">
                            <div className="question-div">
                                <p className="mb-0 body-font-medium">I'm looking for</p>
                                <p className="mb-0 text-muted">Female <i className="fas fa-chevron-down ms-2"></i></p>
                            </div>
                       </div>
                       <div className="col-md-4">
                            <div className="question-div">
                                <p className="mb-0 body-font-medium">Maritial Status</p>
                                <p className="mb-0 text-muted">Never Married <i className="fas fa-chevron-down ms-2"></i></p>
                            </div>
                       </div>
                       <div className="col-4">
                            <div className="question-div">
                                <p className="mb-0 body-font-medium">Location</p>
                                <p className="mb-0 text-muted">Where are you looking for?</p>
                            </div>
                       </div>
                       <div className="col-1 text-end">
                            <div className="search-btn">
                                <button className="btn btn-brand-pink rounded-pill p-2 px-3">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                       </div>
                    </div>
                </div>
                <div className="quote-wrap mt-5 pt-5 pe-5">
                    <div className="d-flex w-50 mt-2">
                        <div className="quote-img-wrap me-2">
                            <img src="assets/QUOTE.png" alt="" className="img-fluid" />
                        </div>
                        <div className="quote-text-wrap">
                            <p className="mb-0 body-font-bold">The Prophet Muhammad (peace be upon him) said,</p>
                            <p className="mb-0">“When a man marries, he has fulfilled half of his religion, so let
                            him fear Allah regarding the remaining half.” (Bukhari)</p>
                        </div>
                    </div>
                </div>
                <div className="search-box-mob-wraper pt-5">
                    <div className="search-box-mob-wrap nav-link p-0 mx-2 bg-white rounded-brand-sm">
                        <div className="row px-0" onClick={localSearchFunc}>
                            <div className="col-5">
                                <div className="question-div py-3 ps-3">
                                    <p className="mb-0 body-font-medium">Looking for</p>
                                    <p className="mb-0 text-muted">Female <i className="fas fa-chevron-down ms-2"></i></p>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="question-div py-3">
                                    <p className="mb-0 body-font-medium">Location</p>
                                    <p className="mb-0 text-muted">Mumbai <i className="fas fa-chevron-down ms-2"></i></p>
                                </div>
                            </div>
                            <div className="col-2 text-end">
                                <button className="btn btn-brand-pink rounded-pill py-3 h-100"><i className='fas fa-search'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-img text-center d-none">
                    <img src="assets/hero-img-sm.png" alt="hero section" className="img-fluid mx-auto text-center" />
                </div>
            </div>
        </div>
        <div className="hero-secton-mob bg-brand-light-pink">
            <div className="container p-0">
                <div className="search-box-wrap mt-5">
                    <div className="search-box row d-flex bg-white p-4 rounded-brand-sm mx-auto px-0">
                       <div className="col-3">
                            <div className="question-div">
                                <p className="mb-0 body-font-medium">I'm looking for</p>
                                <p className="mb-0 text-muted">Female <i className="fas fa-chevron-down ms-2"></i></p>
                            </div>
                       </div>
                       <div className="col-md-3">
                            <div className="question-div">
                                <p className="mb-0 body-font-medium">Maritial Status</p>
                                <p className="mb-0 text-muted">Never Married <i className="fas fa-chevron-down ms-2"></i></p>
                            </div>
                       </div>
                       <div className="col-3">
                            <div className="question-div">
                                <p className="mb-0 body-font-medium">Location</p>
                                <p className="mb-0 text-muted">Where are you looking for?</p>
                            </div>
                       </div>
                       <div className="col-3 text-end">
                            <div className="search-btn">
                                <button className="btn btn-brand-pink rounded-pill p-2 px-3">
                                    <i className="fas fa-search me-2"></i> Search
                                </button>
                            </div>
                       </div>
                    </div>
                </div>
                <div className="hero-text-wrap text-center pt-4">
                    <h1 className="hero-text body-font-heading text-brand-purple mx-auto pt-4">
                        "And we created you in pairs"
                    </h1>
                    <p className="hero-para mb-0 fs-4">
                        [ Al Quran, Surah An-Naba (78), Aayah 8 ]
                    </p>
                </div>
                <div className="search-box-mob-wraper pt-5">
                    <div className="search-box-mob-wrap p-0 mx-2 bg-white rounded-brand-sm">
                        <div className="row nav-link d-flex px-0 mx-0" onClick={localSearchFunc}>
                            <div className="col-6">
                                <div className="question-div py-3 ps-3">
                                    <p className="mb-0 body-font-medium text-nowrap">Looking for <i className="fas fa-chevron-down ms-1"></i></p>
                                    {/* <p className="mb-0 text-muted">Female </p> */}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="question-div py-3">
                                    <p className="mb-0 body-font-medium text-nowrap">Location <i className="fas fa-chevron-down ms-2"></i></p>
                                    {/* <p className="mb-0 text-muted">Mumbai </p> */}
                                </div>
                            </div>
                            <div className="col-2 text-end">
                                <button className="btn btn-brand-pink rounded-pill py-3 h-100"><i className='fas fa-search'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-brand-skin p-3 text-center">
                    <h2 className='body-font-bold fs-6 text-brand-purple'>The Prophet Muhammad (pbuh) said, </h2>
                    <p className="body-font-medium fs-6">
                        “When a man marries, he has fulfilled half of his religion, so let
                        him fear Allah regarding the remaining half.” (Bukhari) <br />
                        [ BUKHARI, P 255, S 56]
                    </p>
                </div>
                <div className="hero-img text-center">
                    <img src="assets/hero-img-sm.png" alt="hero section" className="img-fluid mx-auto text-center" />
                </div>
            </div>
        </div>
        {/* <div className="container hero-quote mt-5">
            <h2 className="fs-4 body-font-medium fst-italic mt-5 pt-5 pb-5">
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis deserunt nesciunt quos modi saepe dolores est mollitia sequi, ea ipsa natus facilis repellendus. Mollitia quibusdam provident exercitationem.
            </h2>
        </div> */}
        <div className="create-new-profile-wrap">
            <div className="container mx-auto row justify-content-center align-items-center px-0">
                <div className="new-profile-img-wrap col-md-5">
                    <img src="assets/man-with-phone.avif" alt="" className="img-fluid" />
                </div>
                <div className="new-profile-img-wrap col-md-5">
                    <h2 className="heading mb-0">About Us</h2>
                    <p className="mb-0 text-muted mt-4">Azdawaj is a matrimonial platform exclusively designed for the Shia Muslim community, where we focus on connecting individuals with shared values and traditions. Our goal is to simplify the process of finding a compatible life partner by offering a secure, respectful, and culturally aligned space for Shia boys and girls to meet and explore potential matches.</p>
                    <p className="mb-0 text-muted mt-4">At Azdawaj, we understand the importance of family, community, and religious values in marriage. Our mission is to bring Shia families closer through a transparent, trustworthy, and user-friendly platform that respects your traditions and prioritizes safety and privacy.</p>
                    <Link to='/signup' className="btn btn-brand-purple rounded-pill p-2 px-3 mt-4">Register Profile</Link>
                </div>
            </div>
        </div>
        <div className="statistics-wrap bg-brand-light-pink py-5 mt-5 d-none">
            <div className="container row mx-auto px-0 mx-0">
                <h2 className="heading mb-5 text-center">Niqah's user statistics</h2>
                <div className="col-md-3 col-6 mb-3">
                    <div className="static-card d-flex flex-column align-items-center justify-content-center">
                        <div className="static-img overflow-hidden rounded-circle p-0 mx-atuo">
                            <img src="assets/couple-img-1.png" alt="" className="img-fluid w-100" />
                        </div>
                        <p className="mb-0 static-numbers text-center body-font-semibold fs-3 mt-3">4,512</p>
                        <p className="static-para text-capitalize body-font-semibold text-center mb-0">Total groom & bride's biodata</p>
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-3">
                    <div className="static-card d-flex flex-column align-items-center justify-content-center">
                        <div className="static-img overflow-hidden rounded-circle p-0 mx-atuo">
                            <img src="assets/male.png" alt="" className="img-fluid w-100" />
                        </div>
                        <p className="mb-0 static-numbers text-center body-font-semibold fs-3 mt-3">1,238</p>
                        <p className="static-para text-capitalize body-font-semibold text-center mb-0">Total groom's biodata</p>
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-3">
                    <div className="static-card d-flex flex-column align-items-center justify-content-center">
                        <div className="static-img overflow-hidden rounded-circle p-0 mx-atuo">
                            <img src="assets/female.png" alt="" className="img-fluid w-100" />
                        </div>
                        <p className="mb-0 static-numbers text-center body-font-semibold fs-3 mt-3">3,673</p>
                        <p className="static-para text-capitalize body-font-semibold text-center mb-0">Total bride's biodata</p>
                    </div>
                </div>
                <div className="col-md-3 col-6 mb-3">
                    <div className="static-card d-flex flex-column align-items-center justify-content-center">
                        <div className="static-img overflow-hidden rounded-circle p-0 mx-atuo">
                            <img src="assets/couple-img-1.png" alt="" className="img-fluid w-100" />
                        </div>
                        <p className="mb-0 static-numbers text-center body-font-semibold fs-3 mt-3">1,490</p>
                        <p className="static-para text-capitalize body-font-semibold text-center mb-0">Total successfull niqas</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="key-features mt-5 container">
            <div className="row flex-wrap-reverse align-items-center px-0 mx-0">
                <div className="col-md-6">
                    <h2 className="heading mb-3">Why Choose <span className="text-brand-pink body-font-bold">Azdawaj.com</span>?</h2>
                    {/* <p className="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis odio nihil beatae!</p> */}
                    <div className="features-wrap">
                        <div className="feature-div d-flex align-items-start mb-3">
                            <i className="fas fa-check-circle mt-1 text-brand-pink me-2"></i>
                            <p className="feature-para mb-0"><span className="body-font-semibold">Exclusive to Shia Muslims:</span> We are proud to serve the Shia community, offering a platform that understands and respects your unique values and traditions.</p>
                        </div>
                        <div className="feature-div d-flex align-items-start mb-3">
                            <i className="fas fa-check-circle mt-1 text-brand-pink me-2"></i>
                            <p className="feature-para mb-0"><span className="body-font-semibold">Guardian Involvement:</span> We facilitate introductions with the active participation of family members, ensuring a respectful and secure process.</p>
                        </div>
                        <div className="feature-div d-flex align-items-start mb-3">
                            <i className="fas fa-check-circle mt-1 text-brand-pink me-2"></i>
                            <p className="feature-para mb-0"><span className="body-font-semibold">Safety First:</span> With no chat features, we aim to keep interactions transparent by arranging virtual meetings, allowing both parties to interact in the presence of their guardians.</p>
                        </div>
                        <div className="feature-div d-flex align-items-start mb-3">
                            <i className="fas fa-check-circle mt-1 text-brand-pink me-2"></i>
                            <p className="feature-para mb-0"><span className="body-font-semibold">Privacy Focused:</span> Your privacy and safety are our top priorities. We take steps to ensure your personal information remains secure and is only shared with your consent.</p>
                        </div>
                    </div>
                    {/* <Link to='somewhere' className='btn btn-brand-purple rounded-pill p-2 px-3'>Learn more</Link> */}
                </div>
                <div className="col-md-6">
                    <img src="assets/man.svg" alt="" className="img-fluid" />
                </div>
            </div>
        </div>
        <div className="steps-wrap bg-brand-light-pink pt-5 container-fluid mt-5 pb-5">
            <div className="steps-wrap-sm">
                <h2 className="heading text-center">How Azdawaj platform works</h2>
                <p className="text-muted text-center">Very easy 4 steps to find your Soulmate</p>
                <div className="steps-div mycontainer row align-items-center mt-5 px-0 mx-0">
                    <div className="col-md-4">
                        <div className="steps-card mb-4">
                            <div className="d-flex align-items-center">
                                <p className="bg-brand-pink mb-2 rounded-pill p-1 text-white px-3 step-count w-max-content me-2">1</p>
                                <p className="fs-4 mb-2 body-font-semibold">Create Your Profile</p>
                            </div>
                            <p className="text-muted mb-0 body-font-semibold">
                            Start by signing up and creating a detailed profile that reflects who you are and what you are looking for in a partner.
                            </p>
                        </div>
                        <div className="steps-card mb-4">
                            <div className="d-flex align-items-center">
                                <p className="bg-brand-purple mb-2 rounded-pill p-1 text-white px-3 step-count w-max-content me-2">2</p>
                                <p className="fs-4 mb-2 body-font-semibold">Search and Discover</p>
                            </div>
                            <p className="text-muted mb-0 body-font-semibold">
                            Use our search feature to browse profiles that meet your preferences in terms of age, education, location, and more.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 steps-img">
                        <img src="assets/steps.png" alt="" className="img-fluid w-100" />
                    </div>
                    <div className="col-md-4  text-end">
                        <div className="steps-card mb-4">
                            <div className="d-flex align-items-center justify-content-end">
                                <p className="bg-brand-purple mb-2 rounded-pill p-1 text-white px-3 step-count w-max-content me-2">3</p>
                                <p className="fs-4 mb-2 body-font-semibold">Send & Receive Requests</p>
                            </div>
                            <p className="text-muted mb-0 body-font-semibold">
                            Once you find a match, you can send a request. If the other person accepts, the match is made.
                            </p>
                        </div>
                        <div className="steps-card mb-4">
                            <div className="d-flex align-items-center justify-content-end">
                                <p className="bg-brand-pink mb-2 rounded-pill p-1 text-white px-3 step-count w-max-content me-2">4</p>
                                <p className="fs-4 mb-2 body-font-semibold">Arranged Google Meets</p>
                            </div>
                            <p className="text-muted mb-0 body-font-semibold">
                            Unlike other platforms, we do not offer chat features. Instead, we arrange a Google Meet between both parties and their guardians for a formal introduction, ensuring transparency and involvement from both families.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mb-5 mt-5">
            <h2 className="fs-4 body-font-extrabold text-center">Join Us Today</h2>
            <p className="mb-0 text-center">
            Azdawaj is more than just a matrimonial platform. It is a community-driven space where you can take the next step in your journey toward finding a life partner, with the guidance and involvement of your loved ones. Start your journey today by creating your profile and discovering matches who share your values and vision for the future.
            </p>
        </div>
    </div>
  )
}

export default Home