import React, { useEffect } from 'react';
import './Contact.css';

function Contact() {

    useEffect(()=> window.scrollTo(0,0), []);

  return (
    <div className='main-contact'>
        <div className="container row mx-auto align-items-center mt-4 p-0">
            <div className="col-md-6 p-0">
                <img src="/assets/contact-venue.jpg" alt="venue" className="img-fluid w-100" />
            </div>
            <div className="col-md-6">
                <p className="mb-0 body-font-bold fs-4">Reach out to us</p>
                <p className="mb-4 text-muted body-font-medium">If you have any doubts, inquiries, suggetions or feedbacks</p>
                <div className="contact-form">
                    <label htmlFor="name" className='body-font-medium'>Your Name</label>
                    <input type="text" id='name' name='name' className='form-control shadow-none outline-none rounded-0 border border-brand-pink' placeholder='Your name' />
                    <label htmlFor="email" className='body-font-medium mt-4'>Your Email</label>
                    <input type="text" id='email' name='email' className='form-control shadow-none outline-none rounded-0 border border-brand-pink' placeholder='Your email' />
                    <label htmlFor="concern" className='body-font-medium mt-4'>Your Concern</label>
                    <textarea name="concern" id="concern" className="border border-brand-pink form-control shadow-none outline-none rounded-0" placeholder='Your concern, inquery, suggetion, feedback, etc...' rows={5}></textarea>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <button className="btn btn-brand-pink rounded-0 px-3"><i className="fas fa-paper-plane me-1"></i> Send</button>
                        <div className='d-flex mb-0'>
                            <i className="fab fa-instagram fs-4 text-brand-purple mb-0 me-3"></i>
                            <i className="fab fa-facebook fs-4 text-brand-purple mb-0 me-3"></i>
                            <i className="fab fa-twitter fs-4 text-brand-purple mb-0 me-3"></i>
                            <i className="fab fa-linkedin fs-4 text-brand-purple mb-0 me-3"></i>
                            <i className="fab fa-youtube fs-4 text-brand-purple mb-0"></i>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                        <div className="email-contact">
                            <p className="mb-1"><i className="fas fa-envelope text-brand-purple me-1"></i> contact.azdawaj@gmail.com </p>
                            <p className="mb-1"><i className="fas fa-phone-alt text-brand-purple me-1"></i> +91 99874 75735</p>
                            <p className="mb-0"><i className="fas fa-map-marker-alt text-brand-purple me-1"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact