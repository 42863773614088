import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Explore from './Pages/Explore/Explore';
import Detail from './Pages/Detail/Detail';
import Profile from './Pages/Profile/Profile';
import Saved from './Pages/Saved/Saved';
import Requests from './Pages/Requests/Requests';
import Signup from './Pages/Signup/Signup';
import Adminregister from './Pages/Adminregister/Adminregister';
import Dashboard from './Pages/Dashboard/Dashboard';
import Adminprofile from './Pages/Adminprofile/Adminprofile';
import Editprofile from './Pages/Editprofile/Editprofile';
import { AuthProvider } from './Context/Authcontext';
import Login from './Pages/Login/Login';
import Passreset from './Pages/Passreset/Passreset';
import Adminlogin from './Pages/Adminlogin/Adminlogin';
import Createadmin from './Pages/Createadmin/Createadmin';
import Alladmins from './Pages/Alladmins/Alladmins';
import Adminproedit from './Pages/Adminproedit/Adminproedit';
import Dashstats from './Pages/Dashstats/Dashstats';
import Matched from './Pages/Matched/Matched';
import Verifyreqs from './Pages/Verifyreqs/Verifyreqs';
import Terms from './Pages/Terms/Terms';
import Privacy from './Pages/Privacy/Privacy';
import About from './Pages/About/About';
import Footer from './Components/Footer/Footer';
import Contact from './Pages/Contact/Contact';
import Delreqs from './Pages/Delreqs/Delreqs';
import Reports from './Pages/Reports/Reports';
import Disclaimer from './Pages/Disclaimer/Disclaimer';
import Support from './Pages/Support/Support';

function App() {
  return (
      <AuthProvider>
        <Router>
          <Navbar/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/explore' element={<Explore/>}/>
            <Route path='/user/:id' element={<Detail/>}/>
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/saved' element={<Saved/>}/>
            <Route path='/requests' element={<Requests/>}/>
            <Route path='/signup' element={<Signup/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/passwordreset/:id' element={<Passreset/>}/>
            <Route path='/editprofile' element={<Editprofile/>}/>
            <Route path='/terms-and-conditions' element={<Terms/>}/>
            <Route path='/privacy-policy' element={<Privacy/>}/>
            <Route path='/customer-support' element={<Support/>}/>
            <Route path='/disclaimer' element={<Disclaimer/>}/>
            <Route path='/about-us' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/adminsignup' element={<Adminregister/>}/>
            <Route path='/admin' element={<Dashboard/>}>
              <Route path='' element={<Adminlogin/>}/>
              <Route path='dashboard' element={<Dashstats/>}/>
              <Route path='profiles/:gender' element={<Adminprofile/>}/>
              <Route path='matched' element={<Matched/>}/>
              <Route path='verification-requests' element={<Verifyreqs/>}/>
              <Route path='deletion-requests' element={<Delreqs/>}/>
              <Route path='reports' element={<Reports/>}/>
              <Route path='admins' element={<Alladmins/>}/>
              <Route path='createprofile' element={<Adminregister/>}/>
              <Route path='createadmin' element={<Createadmin/>}/>
              <Route path='edituser/:id' element={<Adminproedit/>}/>
            </Route>
          </Routes>
          <Footer/>
        </Router>
      </AuthProvider>
  );
}

export default App;
