import React, { useEffect } from 'react';
import './Privacy.css';

function Privacy() {

    useEffect(()=> window.scrollTo(0,0), []);

  return (
    <div className='main-privacy'>
        <div className="container mt-4">
            <h2 className="fs-4 body-font-bold text-center">Privacy Policy</h2>
            <p className="mb-3 text-center">
                By using this site (Azdawaj.com), you are accepting our Privacy Policy.
            </p>
            <div className="full-content mt-5">
                <p className="mb-3">
                    Azdawaj.com is committed to protecting your privacy. This privacy policy outlines how we collect, use, and protect your personal information.
                </p>
                <ol className='terms-ol'>
                    <li>
                        <p className="mb-0 body-font-semibold">Information We Collect</p>
                        <p className="mb-4"><span className='body-font-semibold'>Personal Information</span>: Name, age, gender, marital status, contact information, etc. <br />
                        <span className='body-font-semibold'>Profile Information</span>: Preferences for potential matches, profile details, and other information you provide during signup. <br />
                        <span className='body-font-semibold'>Activity Information</span>: How you use our platform, including search preferences, profile matches, and requests
                        </p>
                    </li>
                    <li>
                        <p className="mb-2 body-font-semibold">How We Use Your Information</p>
                        <p className="mb-0">To create and manage your profile.</p>
                        <p className="mb-0">To facilitate profile matches based on your preferences.</p>
                        <p className="mb-0">To arrange Google Meets between matched users and their guardians.</p>
                        <p className="mb-4">To improve our services and platform functionality.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Sharing Your Information</p>
                        <p className="mb-4">We do not share your personal information with third parties unless required by law or in cases where users consent to share it during the matchmaking process.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Data Security</p>
                        <p className="mb-4">We take appropriate security measures to protect your personal data. However, no system is 100% secure, and we cannot guarantee the absolute security of your information.</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
  )
}

export default Privacy;