import React, { useEffect, useState } from 'react';
import './Adminprofile.css';
import Listingcard from '../../Components/Listingcard/Listingcard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Adminprofile() {

    const { decodeJSON, malepros, femalepros } = useAuth();
    const navigate = useNavigate();
    const {gender} = useParams();
    const [profiles, setProfiles] = useState([]);
    const [proarrs, setProarrs] = useState([]);
    const [mergearr, setMergearr] = useState([]);
    const [pagecount, setPagecount] = useState(1);
    const [admin, setAdmin] = useState({});
    const [females, setFemales] = useState([]);
    const [males, setMales] = useState([]);

    useEffect(()=>{
        fetch('https://shiawedding.onrender.com')
        .then(res => res.json())
        .then(data => {
            const allmaleid = malepros.map(e => e._id);
            const allfemaleid = femalepros.map(e => e._id);
            const allproids = [...allmaleid, ...allfemaleid];
            const allpros = [];
            data.forEach(e => {
                if(allproids.includes(e._id)){
                    allpros.push(e);   
                }
            });
            setProfiles(allpros);
        }).catch(err => {
            console.log('error occured while getting all the profiles in the admin dashboard ', err);
        })
    }, [malepros, femalepros]);

    useEffect(()=> {
        const femalecontext = [];
        const malecontext = [];
        femalepros.forEach(f => {
            femalecontext.push(f._id)
        })
        malepros.forEach(f => {
            malecontext.push(f._id)
        })
        if(gender && gender.length > 0 && profiles.length > 0){
            if(gender === 'female'){
                const femalePros = profiles.filter(e => e.gender === 'Female' && femalecontext.includes(e._id));
                // console.log(femalePros);
                // console.log(profiles);
                setFemales(femalePros);
            }else if(gender === 'male'){
                const malePros = profiles.filter(e => e.gender === 'Male' && malecontext.includes(e._id));
                // console.log(malePros);
                setMales(malePros);
            }
        }
    }, [gender, profiles, femalepros, malepros]);

    // useEffect(()=> console.log(femalepros, malepros), [femalepros, malepros])

    // useEffect(()=> {
    //     if(gender && gender.length > 0){
    //         if(gender === 'male'){
    //             setProfiles(males);
    //         }else if(gender === 'female'){
    //             setProfiles(females);
    //         }
    //     }
    // }, [gender, males, females])

    

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(!localuser || localuser.length < 1){
            navigate('/admin/');
        }
    }, [navigate])

    const chunking = (arr, size) => {
        const result = [];
        for(let i = 0; i < arr.length; i += size){
            result.push(arr.slice(i, i + size));
        };
        return result;
    }

    useEffect(()=> {
        if(profiles.length > 0){
            if(gender === 'male'){
                const chunkarr = chunking(males, 9);
                setProarrs(chunkarr)
            }else if(gender === 'female'){
                const chunkarr = chunking(females, 9);
                setProarrs(chunkarr);
            }else{
                const chunkarr = chunking(profiles, 9);
                setProarrs(chunkarr)
            }
        }
    }, [profiles, gender, males, females]);

    useEffect(()=> {
        // console.log(proarrs);
        setMergearr(proarrs[0])
    }, [proarrs]);

    // useEffect(()=> {
    //     console.log(mergearr);
    // }, [mergearr]);

    const loadMore = () => {
        // console.log(proarrs.length)
        if(pagecount < proarrs.length){
            setMergearr(x => [...x, ...proarrs[pagecount]])
        }
    }

    useEffect(()=> {
        const adlocprodat = localStorage.getItem("adlocprodat");
        if(adlocprodat){
            setAdmin(decodeJSON(adlocprodat));
        }
    }, [decodeJSON]);

  return (
    <div className='main-adminprofiles'>
        <div className="profile-card-wrap row p-0">
            {
                mergearr?.map((pro, ind) => (
                    <div key={ind} className="col-md-4 mb-3">
                        <Listingcard userid={pro.randomid} num={99} verified={pro.verified} reqs={pro.reqreceived} createdby={pro.createdfor} lastseen={pro.lastseen} admin={admin.usertype} email={pro.email} pass={pro.password} blur={pro.profileblur} img={`https://shiawedding.onrender.com/images/${pro?.profileimage?.split('/')[1]}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                    </div>
                ))
            }
            <div className="col-md-12 text-center" style={{display: pagecount >= proarrs.length && 'none'}}>
                <button className="btn btn-brand-pink p-2 px-3 rounded-brand-sm" onClick={()=> {setPagecount(pagecount + 1); loadMore()}}>Load more</button>
            </div>
        </div>
    </div>
  )
}

export default Adminprofile