import React, { useEffect, useState } from 'react';
import './Reports.css';
import Listingcard from '../../Components/Listingcard/Listingcard';
import { useAuth } from '../../Context/Authcontext';

function Reports() {

    const {decodeJSON} = useAuth();
    const [reportpros, setReportpors] = useState([]);
    const [allpro, setAllpro] = useState([]);
    const [admin, setAdmin] = useState({});
    
    useEffect(()=> {
        const adlocprodat = localStorage.getItem("adlocprodat");
        if(adlocprodat){
            setAdmin(decodeJSON(adlocprodat));
        }
    }, [decodeJSON]);

    useEffect(()=> {
        fetch(`https://shiawedding.onrender.com/getallreports`).then(res => res.json()).then(data => {
            if(data.success){
                setReportpors(data.pro);
            }else{
                console.log('we did not get any profiles');
                console.log(data);
            }
        });
    }, []);

    useEffect(() => {
        if(reportpros && reportpros?.length > 0){
            Promise.all(reportpros.map(pro => fetch(`https://shiawedding.onrender.com/user/${pro.profileid}`).then(res => res.json()))).then(data => {
                console.log(data);
                setAllpro(data);
            }).catch(err => console.log('error occured while getting all the reported profile ', err));
        }
    }, [reportpros]);

    useEffect(() => console.log(allpro), [allpro]);

  return (
    <div className='main-report'>
        <div className="allpro-wrap row">
            {
                allpro?.map((pro, ind) => (
                    <div key={ind} className="col-md-4 mb-3">
                        <Listingcard userid={pro.randomid} num={99} verified={pro.verified} reqs={pro.reqreceived} createdby={pro.createdfor} lastseen={pro.lastseen} admin={admin.usertype} email={pro.email} pass={pro.password} blur={pro.profileblur} img={`https://shiawedding.onrender.com/images/${pro?.profileimage?.split('/')[1]}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Reports;